/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import AccountService from '../../services/account.service';
import AlertService from '../../services/alerts.service';
import CommentsService from '../../services/comments.service';
import PartnerService from '../../services/partner.service';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import BaseAlertPage from '../LWTriageAlerts/BaseAlertPage';
import FullScreenChartModal from '../LWTriageAlerts/FullScreenChartModal';
import AlertEditModal from '../LWTriageAlerts/AlertEditModal';
import CreateJobModal from '../LWTriageAlerts/CreateJobModal';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import memoize from 'memoize-one';
import moment from 'moment';
import { PageView,  Event, Timing } from "../GoogleAnalytics";

//import { jsAsset, alertCustomer, jsAlert, jsGraph, jsOpportunity, jsUpdateOpportunity, jsGraphData } from "../../componentObjects";

import CssBaseline from '@mui/material/CssBaseline';
import PropTypes from 'prop-types';
import TriageAlertsTable from '../TriageAlerts/TriageAlertsTable';
import { AlertTypeIdsEnum, TagIdsEnum, jsAccount } from '../../componentObjects';
import alerttypemessagingService from "../../services/alerttypemessaging.service";

class CustomerAlerts extends Component {
    static displayName = CustomerAlerts.name;
    _isMounted = false;

    constructor(props) {
        super(props);

        this.getAlerts = this.getAlerts.bind(this);
        this.changeRadio = this.changeRadio.bind(this);
        this.reloadTable = this.reloadTable.bind(this);
        this.filterAlerts = this.filterAlerts.bind(this);
        this.consolidateAlerts = this.consolidateAlerts.bind(this);
        this.addConversationToAlert = this.addConversationToAlert.bind(this);
        this.updateAlertList = this.updateAlertList.bind(this);
        this.getAccount = this.getAccount.bind(this);

        this.state = {
            alertArgs: {
                queryId: "custom",
                startTime: null,
                endTime: null,
                isActive: true,
                priority: 3
            },
            alertType: "Active",
            alertList: [],
            currentAccount: jsAccount,
            loading: true,
            alertTableLoading: false,
            alertMessaging: [],
            assetComments: [],
            orgId: this.props.currentCustomerId,
            partnerInfo: {},
            tableObj: {},
            startTabIndex: 0,
            consolidateAlertsDict: {}
        }
    }

    async componentDidMount() {
        this._isMounted = true;
        PageView();
        this.getAccount(this.props.currentCustomerId);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.currentCustomerId !== this.props.currentCustomerId) {
            this.getAccount(this.props.currentCustomerId);
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    memAlerts = memoize(this.getAlerts);

    async getAlerts(id, start = null, end = null) {
        let startTime = performance.now();
        let now = (end == null ? moment() : end);
        let before = (start == null ? moment(now).add(-5, 'years') : start);
        let nowStr = now.format();
        let beforeStr = before.format();
        let nowDT = new Date(nowStr);
        let beforeDT = new Date(beforeStr);
        let startTabIndex = 0;
        let alertList = [];
        let ignoreIsClosed = true;

        this.setState(prevState => ({
            alertTableLoading: true,
            orgId: this.props.currentCustomerId,
            alertArgs: {
                ...prevState.alertArgs,
                startTime: beforeStr,
                endTime: nowStr
            }
        }), async () => {
            await alerttypemessagingService.getalertmessaging()
                .then(response3 => {
                    if (this._isMounted)
                        this.setState({ alertMessaging: response3 }, async () => {
                            await CommentsService.getassetcomments()
                            .then(response6 => {
                                this.setState({ assetComments: response6.data }, async () => {
                                    let request = {
                                        startTime: beforeDT,
                                        endTime: nowDT,
                                        organizationId: this.props.currentCustomerId,
                                        alertMsgList: this.state.alertMessaging,
                                        ignoreIsClosed: ignoreIsClosed
                                    }
                                    await AlertService.gethistoricalalertslist(request)
                                        .then(response5 => {
                                            this.setState({ tableObj: response5.data }, () => {

                                                let filteredEquipAlertList = this.state.tableObj.equipmentAlertsList !== undefined && this.state.tableObj.equipmentAlertsList !== null ? this.state.tableObj.equipmentAlertsList : [];


                                                if (filteredEquipAlertList.length > 0) {
                                                    alertList = filteredEquipAlertList;
                                                    startTabIndex = 0;
                                                }
                                                else {

                                                    alertList = this.state.tableObj.deviceAlertsList !== null ? this.state.tableObj.deviceAlertsList : [];
                                                    startTabIndex = 1;
                                                }
                                                this.setState({ loading: false, alertTableLoading: false, /* alertList: alertList,*/ startTabIndex: startTabIndex }, () => {
                                                    this.consolidateAlerts(alertList);
                                                });
                                            });
                                        })
                                })
                            })
                        });
                })
                .catch(e => {
                    console.log(e);
                });
        });

    }

    async getAccount(id) {
        var startTime = performance.now();
        await AccountService.get(id)
            .then(response => {
                if (this._isMounted) {
                    this.setState({
                        currentAccount: response.data,
                        loading: false,
                        alertTableLoading: true
                    }, async () => {
                        this.getAlerts();
                        var elapsedTime = performance.now() - startTime;
                        Timing("Account Loading", "loading", elapsedTime, "Customer Account Loading");
                    });
                }
            })
            .catch(e => {
                console.log(e);
                this.setState({
                    loading: false
                });
            });
    }

    consolidateAlerts(alerts) {
        let distinctAlerts = [];
        let collapseAlerts = [];
        let consolidateAlertsDict = {};
        let collapseUnitAlertsDict = {}; // {{ assetId: assetId used in consolidateDict }}
        let isDevAlerts = this.state.tabIndex === 1;

        for (let i = 0; i < alerts.length; i++) {
            let currAlert = alerts[i];

            if (!distinctAlerts.some(a => a.typeId == currAlert.typeId && a.alert.assetId == currAlert.alert.assetId)) {
                var assetTypeAlerts = alerts.filter(a => a.typeId == currAlert.typeId && a.alert.assetId == currAlert.alert.assetId);
                var alertConversationId = Math.max(...assetTypeAlerts.map(o => o.alert.conversationId));
                var alertCommentCount = assetTypeAlerts.find(a => a.alert.conversationId == alertConversationId);

                assetTypeAlerts.sort((a, b) => {
                    const startA = new Date(a.alert.startTime);
                    const startB = new Date(b.alert.startTime);
                    return startB - startA;
                });

                var distinctAlert = [...assetTypeAlerts][0];
                let distinctUnitId = distinctAlert.alert.pathNames !== null && distinctAlert.alert.pathNames.length > 2 ? distinctAlert.alert.pathNames[2].split('-')[0] : null;

                if (isDevAlerts && distinctAlert.typeId == AlertTypeIdsEnum.UnitOffline && distinctUnitId !== null && distinctAlerts.some(d => d.typeId == distinctAlert.typeId && d.alert.pathNames.length > 2 && d.alert.pathNames[2].split('-')[0] == distinctUnitId)) {
                    let prevDistinctAlert = distinctAlerts.find(d => d.typeId == distinctAlert.typeId && d.alert.pathNames.length > 2 && d.alert.pathNames[2].split('-')[0] == distinctUnitId);
                    let prevUnitAlerts = consolidateAlertsDict[distinctAlert.typeId][prevDistinctAlert.alert.assetId];

                    let unitAlerts = prevUnitAlerts.concat(assetTypeAlerts);

                    collapseAlerts = collapseAlerts.concat(distinctAlert);
                    collapseUnitAlertsDict[distinctAlert.alert.assetId] = prevDistinctAlert.alert.assetId;
                    consolidateAlertsDict[prevDistinctAlert.typeId][prevDistinctAlert.alert.assetId] = unitAlerts;
                }
                else {
                    if (alertConversationId != 0) {
                        distinctAlert.alert.conversationId = alertConversationId;
                        distinctAlert.commentsCount = alertCommentCount.alert.conversationItemCount;
                        distinctAlert.alert.conversationItemCount = alertCommentCount.alert.conversationItemCount;
                    }
                    distinctAlerts = distinctAlerts.concat(distinctAlert);

                    if (consolidateAlertsDict[currAlert.typeId] === undefined) {
                        consolidateAlertsDict[currAlert.typeId] = {};
                        consolidateAlertsDict[currAlert.typeId][currAlert.alert.assetId] = assetTypeAlerts;
                    }
                    else {
                        consolidateAlertsDict[currAlert.typeId][currAlert.alert.assetId] = assetTypeAlerts;
                    }
                }
            }
        }

        /*let equipAlerts = this.state.tableObj.equipmentAlertsList;
        let equipTypeIds = [];
        equipAlerts.forEach(a => {
            if (!equipTypeIds.includes(a.typeId))
                equipTypeIds = equipTypeIds.concat(a.typeId);
        });

        for (let i = 0; i < equipTypeIds.length; i++) {
            let currTypeId = equipTypeIds[i];

            if (consolidateAlertsDict[currTypeId] === undefined) {
                consolidateAlertsDict[currTypeId] = {};
            }

            let typeAlerts = equipAlerts.filter(a => a.typeId === currTypeId);
            let typeAssetIds = [];
            typeAlerts.forEach(t => {
                if (typeAssetIds.includes(t.alert.assetId))
                    typeAssetIds = typeAssetIds.concat(t.alert.assetId);
            });

            for (let j = 0; j < typeAssetIds.length; j++) {
                let currAssetId = typeAssetIds[j];
                let typeAssetAlerts = typeAlerts.filter(t => t.alert.assetId === currAssetId);

                consolidateAlertsDict[currTypeId][currAssetId] = typeAssetAlerts;
            }
        }

        let devAlerts = this.state.tableObj.deviceAlertsList;
        let devTypeIds = [];
        devAlerts.forEach(a => {
            if (!devTypeIds.includes(a.typeId))
                devTypeIds = devTypeIds.concat(a.typeId);
        });

        for (let i = 0; i < devTypeIds.length; i++) {
            let currTypeId = devTypeIds[i];

            if (consolidateAlertsDict[currTypeId] === undefined) {
                consolidateAlertsDict[currTypeId] = {};
            }

            let typeAlerts = devAlerts.filter(a => a.typeId === currTypeId);

            let typeAssetIds = [];
            typeAlerts.forEach(t => {
                if (typeAssetIds.includes(t.alert.assetId))
                    typeAssetIds = typeAssetIds.concat(t.alert.assetId);
            });

            for (let j = 0; j < typeAssetIds.length; j++) {
                let currAssetId = typeAssetIds[j];
                let typeAssetAlerts = typeAlerts.filter(t => t.alert.assetId === currAssetId);

                consolidateAlertsDict[currTypeId][currAssetId] = typeAssetAlerts;
            }
        }*/

        this.setState({ alertList: distinctAlerts, consolidateAlertsDict: consolidateAlertsDict, alertTableLoading: false });
    }

    async filterAlerts(tabIndex, radio, isActive, isScheduled, selectedTypes) {
        if (this.state.tableObj.equipmentAlertsList !== null && this.state.tableObj.equipmentAlertsList !== undefined)
            this.setState({ alertTableLoading: true }, () => {
                let alerts = tabIndex == 0 ? this.state.tableObj.equipmentAlertsList : this.state.tableObj.deviceAlertsList;

                if (radio === "Critical")
                    alerts = alerts.filter(a => a.severity === "Critical");
                else if (radio === "Warning")
                    alerts = alerts.filter(a => a.severity === "Warning");

                if (isActive !== null) {
                    if (isActive == true)
                        alerts = alerts.filter(a => a.alert.isActive == true);
                    else
                        alerts = alerts.filter(a => a.alert.isActive == false);
                }

                if (isScheduled !== null) {
                    if (isScheduled == true)
                        alerts = alerts.filter(a => a.alert.isMuted == true);
                    else
                        alerts = alerts.filter(a => a.alert.isMuted == false);
                }

                this.consolidateAlerts(alerts); //this.setState({ alertList: alerts, alertTableLoading: false });  
            });
    }

    async reloadTable() {
        this.setState({
            alertTableLoading: true,
            alertList: []
        });

        this.setState(prevState => ({
            alertArgs: {
                ...prevState.alertArgs,
                isActive: this.state.alertType === "Inactive" ? false : true
            }
        }));

        await this.getAlerts();
    }

    async changeRadio(val) {
        let x = val;
        Event("Customer Alerts Radio Clicked", "User clicked one of the radio buttons for the alert table in Customer Alerts", "Customer Alerts Radio Clicked");

        this.setState({
            alertType: x,
            alertList: []
        });

        this.setState(prevState => ({
            alertArgs: {
                ...prevState.alertArgs,
                isActive: x === "Inactive" ? false : true
            }
        }));

        this.setState({ alertTableLoading: true }, async () => {
            await this.getAlerts();
        });
    }

    addConversationToAlert(conversationId, alertId) {
        var index = 0;
        var alert = null;

        index = this.state.tableObj.alerts.findIndex(a => {
            return a.alertId === alertId;
        });

        var tempTableObj = [...this.state.tableObj.alerts];
        alert = { ...tempTableObj[index] };
        alert.conversationId = conversationId.toString();
        tempTableObj[index] = alert;

        this.setState(prevState => ({
            tableObj: {
                ...prevState.tableObj,
                alerts: tempTableObj
            }
        }));
    }

    updateAlertList(alertsTable) {
        this.setState({ alertList: alertsTable });
    }

    render() {
        const { classes } = this.props;
        return (
            <div sx={(theme)=>({
                [theme.breakpoints.down('md')]: {
                    marginLeft: '-225px !important',
                },
            })}>
                <div className="infoPage">
                    <h2 className="pageTitle" id="tabelLabel" style={{ borderBottom: 'none', padding: '20px 0px 0px 30px' }}>
                        Alert History for {this.state.currentAccount.name}
                    </h2>

                    <TriageAlertsTable alertList={this.state.alertList} startTabIndex={this.state.startTabIndex} _isMounted={this._isMounted} loading={this.state.loading} alertTableLoading={this.state.alertTableLoading} alertMessaging={this.state.alertMessaging} assetComments={this.state.assetComments}  tableObj={this.state.tableObj}
                        filterAlerts={this.filterAlerts} reloadTable={this.reloadTable} customers={this.props.customers} consolidateAlertsDict={this.state.consolidateAlertsDict} addConversationToAlert={this.addConversationToAlert} userOrg={this.props.userOrg} updateAlertList={this.updateAlertList}
                        setActivePath={this.props.setActivePath} source="CustomerAlerts" updateSearch={this.getAlerts} />

                    {/*<BaseAlertPage alertList={this.state.alertList} getAlerts={this.getAlerts} changeRadio={this.changeRadio} reloadTable={this.reloadTable} _isMounted={this._isMounted} alertTableLoading={this.state.alertTableLoading} loading={this.state.loading} showButtons={true}
                        setCurrentPartnerAndCustomer={this.props.setCurrentPartnerAndCustomer} setCurrentPartnerId={this.props.setCurrentPartnerId} setCurrentCustomerId={this.props.setCurrentCustomerId} setActivePath={this.props.setActivePath} alertMessaging={this.state.alertMessaging} partnerInfo={this.state.partnerInfo} />*/}
                </div>
            </div>
        );
    }
}

export default CustomerAlerts;