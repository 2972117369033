/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { Spinner, Image } from 'react-bootstrap';
import { PageView, Event } from "../GoogleAnalytics";
import DatePicker from 'react-datepicker';
import DailyStatisticsService from "../../services/dailyStatistics.service";
import PartnerService from "../../services/partner.service";
import { roles } from "../../componentObjects";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import EnergyMetricsTable from './EnergyMetricsTable';
import Loader from "../Loader/Loader";

const reprovisionClassId = "70";
const csvBaseName = "EnergyMetrics.csv";
const currentDate = new Date();
const month = currentDate.getMonth() + 1;
const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const columnsToExclude = ['partnerOrgId', 'customerOrgId', 'homeId', 'hvacId', 'assetId', 'events'];


function modifyArray(arrayOfObjects) {
    const modifiedArray = arrayOfObjects.map(obj => {
        const modifiedObj = { ...obj }; 
        columnsToExclude.forEach(variable => {
            delete modifiedObj[variable];
        });
        return modifiedObj;
    });
    return modifiedArray;
}

function convertToCSV(objArray) {
    const array = [Object.keys(objArray[0])].concat(objArray.map(obj => Object.values(obj)));
    return array.map(row => row.join(',')).join('\n');
};

function downloadCSV(data, date, isPartnerAdmin) {
    Event("Download Data Clicked", "User clicked download data button in Partner Energy Metrics", "Download Data Clicked");
    var filenameWithDate = months[date.getMonth()] + "_" + csvBaseName;
    var modifiedData = isPartnerAdmin ? modifyArray(data) : data;
    const csvContent = "data:text/csv;charset=utf-8," + encodeURIComponent(convertToCSV(modifiedData));

    const link = document.createElement("a");
    link.setAttribute("href", csvContent); // Set csvContent directly
    link.setAttribute("download", filenameWithDate || "data.csv");
    document.body.appendChild(link);
    link.click();
};

class PartnerEnergyMetrics extends Component {
    static displayName = "Energy Metrics";
    static defaultSortCol = "CUSTOMER";
    _isMounted = false;

    constructor(props) {
        super(props);

        this.calculateMonthlyData = this.calculateMonthlyData.bind(this);
        this.populateData = this.populateData.bind(this);
        this.handleCloseDownloadMenu = this.handleCloseDownloadMenu.bind(this);
        this.handleDownloadMenu = this.handleDownloadMenu.bind(this);
        this.handleDownloadMenuSelect = this.handleDownloadMenuSelect.bind(this);

        this.state = {
            loading: true,
            monthlyDataObj: {},
            summarizedData: {},
            reprovisionAssetIdList: [],
            columnSort: {
                enabled: true,
                type: this.defaultSortCol,
                reverse: false
            },
            openDownloadMenu: false,
            dateSelected: currentDate,
            selected: -1,
            selectedDeviceName: ""
        };
    }

    componentDidMount() {
        this._isMounted = true;
        PageView();
        this.populateData(this.state.dateSelected);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.currentPartnerId !== this.props.currentPartnerId) {
            this.populateData(this.state.dateSelected);
        }
    }
    populateData(date) {
            this.setState({ loading: true }, () => {
                let yearSelected = date.getFullYear();
                let monthSelected = date.getMonth() + 1;
                var partnerId = this.props.currentPartnerId;
                PartnerService.searchpartnerassets(partnerId, reprovisionClassId).then(response => {
                    if (this._isMounted) {
                        var reprovisionAssetList = response.map(item => item.assetId);
                        this.setState({ reprovisionAssetIdList: reprovisionAssetList }, () => {
                            DailyStatisticsService.monthlypartnerdata(monthSelected, yearSelected, partnerId).then(response2 => {
                                if (this._isMounted) {
                                    var filteredData = this.filterReprovisionAssets(response2.data);
                                    var calculatedData = this.calculateMonthlyData(filteredData);
                                    this.setState({ monthlyDataObj: filteredData, summarizedData: calculatedData, loading: false });
                                }
                                else {
                                    this.setState({ loading: false })
                                }
                            });
                        });
                    }
                    else {
                        this.setState({ loading: false })
                    }
                });
            })
    }

    filterReprovisionAssets(data) {
        let filteredList = [];
        data.forEach(entry => {
            const { assetId } = entry;
            if (this.state.reprovisionAssetIdList.includes(assetId)) {
                return;
            }
            else {
                filteredList.push(entry);
            }
        });
        return filteredList;
    }

    calculateMonthlyData(data) {
        // Initialize an empty object to store summarized data
        const summarizedData = {};

        // Iterate over each data entry
        data.forEach(entry => {
            // Extract necessary information
            const { deviceName, assetId, customerName, homeName, hvacName, assetType, runDate, amps, watts, kilowattHours, runs, cost, gasCost, timeRunning, alerts, equipmentAlerts, deviceAlerts } = entry;
            let date = new Date(runDate);
            const monthYear = `${date.getMonth() + 1}-${date.getFullYear()}`;

            // If the device is not in the summarized data, initialize its entry
            if (!summarizedData[deviceName]) {
                summarizedData[deviceName] = {
                    customer: customerName,
                    homeName: homeName,
                    hvacName: hvacName,
                    assetType: assetType,
                    kilowattHours: 0,
                    amps: 0,
                    watts: 0,
                    runs: 0,
                    cost: 0,
                    gasCost: 0,
                    timeRunning: 0,
                    dayCount: 0,
                    alerts: 0,
                    deviceAlerts: 0,
                    equipmentAlerts: 0
                };
            }

            // Increment the count of entries for the device
            summarizedData[deviceName].dayCount++;

            // Sum up the values for each device
            summarizedData[deviceName].amps += amps;
            summarizedData[deviceName].watts += watts;
            summarizedData[deviceName].kilowattHours += kilowattHours;
            summarizedData[deviceName].runs += runs;
            summarizedData[deviceName].cost += cost;
            summarizedData[deviceName].gasCost += gasCost;
            summarizedData[deviceName].timeRunning += timeRunning;
            summarizedData[deviceName].alerts += alerts;
            summarizedData[deviceName].equipmentAlerts += equipmentAlerts;
            summarizedData[deviceName].deviceAlerts += deviceAlerts;
        });

        // Format Summary Data
        Object.keys(summarizedData).forEach(deviceName => {
            const { amps, watts, kilowattHours, cost, gasCost, count } = summarizedData[deviceName];
            summarizedData[deviceName].amps = amps.toFixed(2);
            summarizedData[deviceName].watts = watts.toFixed(2);
            summarizedData[deviceName].kilowattHours = kilowattHours.toFixed(2);
            summarizedData[deviceName].cost = cost.toFixed(2);
            summarizedData[deviceName].gasCost = gasCost.toFixed(2);
         });

        var summarizedDataList = Object.entries(summarizedData).map(([key, value]) => ({
            deviceName: key,
            ...value
        }));

        return summarizedDataList;
    }


    handleDownloadMenu(event) {
        this.setState({
            anchorEl: event.currentTarget,
            openDownloadMenu: !this.state.openDownloadMenu
        });
    }

    handleCloseDownloadMenu() {
        this.setState({
            openDownloadMenu: false
        })
    }

    handleDownloadMenuSelect(isMonthSummary = false) {
        this.setState({ openDownloadMenu: false }, () => {
            var isPartnerAdmin = this.props.roles.includes(roles.partnerAdmin);
            if (isMonthSummary) {
                downloadCSV(this.state.summarizedData, this.state.dateSelected, isPartnerAdmin)
            }
            else {
                downloadCSV(this.state.monthlyDataObj, this.state.dateSelected, isPartnerAdmin)
            }
        });

    }

   
    renderDatePicker() {

        const handleChange = async date => {
            this.setState({ dateSelected: date, loading: true });
            await this.populateData(date);
        };

        return (
            <div style={{ textAlign: 'center' }}>
                <div style={{ marginLeft: '10px' }}>Data From:</div>
                <DatePicker
                    customInput={<input className="txt-detail" style={{ minWidth: '200px', width: '15%', textAlign: "center" }} />}
                    selected={this.state.dateSelected}
                    onChange={handleChange}
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    popperPlacement="auto"
                    popperProps={{
                        positionFixed: true
                    }}
                />
            </div>
        );
    }

    render() {
        const { classes } = this.props;
        var isPartnerAdmin = this.props.roles.includes(roles.partnerAdmin);

        let downloadBtn =
            <div className="row no-gutters alerts-tab-wrapper" style={{ margin: '0 30px', padding: '10px 0' }}>
                <div className="btn-right" style={{ padding: '25px 25px 25px 30px' }}>
                    <button className="primary-btn btn-large" style={{ width: 150 + 'px' }} onClick={this.handleDownloadMenu}>Download Data</button>     
               </div>
               <Menu
                    id="menu-download"
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={this.state.openDownloadMenu}
                    onClose={this.handleCloseDownloadMenu}
                >
                    <MenuItem onClick={() => { this.handleDownloadMenuSelect(true)}}>Month Summary</MenuItem>
                    <MenuItem onClick={() => { this.handleDownloadMenuSelect(false) }}>Daily Summary</MenuItem>
                </Menu>
            </div>



        let tableContents = this.state.loading ?
            <Loader />
            : 
                <EnergyMetricsTable isLoading={this.state.loading} monthlyDataObj={this.state.monthlyDataObj} summarizedData={this.state.summarizedData} dateSelected={this.state.dateSelected} />;
            ;

        let datePicker = this.renderDatePicker();
        return (

            <div>
                <div >
                    <div className='infoPage'>
                        <h2 className="pageTitle" id="tabelLabel" style={{ borderBottom: 'none', padding: '20px 0px 0px 30px' }}>
                            Partner Energy Metrics
                        </h2>
                        {downloadBtn}
                        {datePicker}
                        { tableContents }
                    </div>
                </div>
            </div>

        );
    }
}

export default PartnerEnergyMetrics;
