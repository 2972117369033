import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { Event, PageView, ModalView, Timing } from "../GoogleAnalytics";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import LongArrowUpIcon from '@mui/icons-material/ArrowUpward';
import LongArrowDownIcon from '@mui/icons-material/ArrowDownward';
import AccountService from "../../services/account.service";
import AssetService from "../../services/asset.service";
import CustomerService from "../../services/customer.service";
import HomeService from '../../services/home.service';

import ConfirmPasswordModal from "../Account/ConfirmUser/ConfirmPasswordModal";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import UserDeviceList from "./UserDeviceList";
import ConfirmDeleteSystemModal from "../Account/ConfirmDeleteSystemModal";
import SelectHomeDataModal from "./SelectHomeDataModal";

import ConfirmUserPasswordForm from "../Account/ConfirmUser/ConfirmUserPasswordForm";
import Loader from "../Loader/Loader";

function DeleteSystems(props) {

    const { classes, isLoading, currentPartnerId, _isMounted } = props;
    const [isMounted, setIsMounted] = useState(false);
    const [deletedSystems, setDeletedSystems] = useState([]);
    const [loading, setLoading] = useState(true);

    // Delete Flow
    const [showCancelModal, setShowCancelModal] = useState(false); 
    const [showConfirmCancelModal, setShowConfirmCancelModal] = useState(false);
    const [showDeleteSystem, setShowDeleteSystem] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);
    const [showRecoverConfirmation, setShowRecoverConfirmation] = useState(false);
    const [message, setMessage] = useState("");
    const [isDeleted, setIsDeleted] = useState(false);
    const [waitDelete, setWaitDelete] = useState(false);
    const [userassethierarchy, setuserassethierarchy] = useState([]);
    const [isActiveBillingPlan, setIsActiveBillingPlan] = useState(false);
    const [currentSystemId, setCurrentSystemId] = useState("");
    const [modalTitle, setModalTitle] = useState("");

    const [currentHomeList, setCurrentHomeList] = useState("");
    const [currentCustomerOrg, setCurrentCustomerOrg] = useState("");
    const [selectedHome, setSelectedHome] = useState("");

    const [columnAltered, setColumnAltered] = useState(false);
    const [columnList, setColumnList] = useState([]);
    const [columnSort, setColumnSort] = useState({
        enabled: true,
        type: "DATE",
        reverse: false
    });
    const actionMessage = "Please Retry or Contact Support";

    const fetchDeletedSystems = async (partnerId) => {
        try {
            if (_isMounted && isMounted) {
                PageView();
                await AccountService.partnerdeletedsystems(partnerId)
                    .then(response => {
                        if (_isMounted && isMounted) {
                            const data = response.data;
                            setDeletedSystems(data);
                            console.log(isDeleted);
                            setLoading(false);
                        }
                    });
            }
        } catch (error) {
            console.error('Error fetching deleted Systems:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        setIsMounted(true);
        fetchDeletedSystems(currentPartnerId);

        return () => {
            setIsMounted(false);
        };
    }, [currentPartnerId, isMounted]);

    const onHomeChange = (e) => {
        const newHomeId = e;
        setSelectedHome(newHomeId);      
    }

    // DELETE FLOWS:
    const showConfirmationModal = e => {
        if (!showConfirmation) {
            setCurrentSystemId(e.assetId);
            checkSystemAssets(e.assetId);
            ModalView("../ConfirmationModal/ConfirmationModal");
        }
        setShowConfirmation(!showConfirmation);
        setModalTitle(!showConfirmation ? "Delete System" : "");
        window.scrollTo(0, 0);
    }

    const showRecoverConfirmationModal = e => {

        if (!showRecoverConfirmation) {
            setCurrentSystemId(e.assetId);
            var customerOrg = "";
            // get name 
            var name = e.orgName.split('/')[1];
            name = name.substr(1);
            CustomerService.get(currentPartnerId)
                .then(response1 => {
                    // get customer org
                    for (var i = 0; i < response1.length; i++) {
                        if (response1[i].name == name) {
                            customerOrg = response1[i].organizationId;
                        }
                    }
                    // get home list
                    HomeService.get(customerOrg)
                        .then(response2 => {
                            setCurrentHomeList(response2.data);
                            setSelectedHome(response2.data[0]);
                            setCurrentCustomerOrg(customerOrg);
                        })
                })
            // modal view 
            ModalView("/selectHomeDataModal");
        }
        setShowRecoverConfirmation(!showRecoverConfirmation);
        window.scrollTo(0, 0);
    }

    const showPasswordConfirmationModal = () => {
        setShowPasswordConfirmation(!showPasswordConfirmation);
        setShowCancelModal(false);
        setShowConfirmCancelModal(false);
        setShowConfirmation(false, () => {
            if (showPasswordConfirmation) {
                ModalView("ConfirmPasswordModal");
            }
        });

        window.scrollTo(0, 0);
    }

    const cancelSystemDelete = () => {
        setShowRecoverConfirmation(false);
        setShowCancelModal(false);
        setShowConfirmCancelModal(false);
        setShowConfirmation(false);
    }

    const showDeleteSystemModal = e => {
        setShowDeleteSystem(!showDeleteSystem);
        setMessage(!showDeleteSystem ? "Would you like to delete the System?" : "");
        setShowPasswordConfirmation(false);
        setShowCancelModal(false);
        setShowConfirmCancelModal(false);
        setShowConfirmation(false, () => {
            if (showDeleteSystem) {
                ModalView("/confirmDeleteSystem");
            } else if (isDeleted) {
                // todo: Don't need to do this, just refresh list of deleted accounts
                //this.props.setCurrentPartnerAndCustomer(this.state.currentAccount.parentAccountId, 0, this.setPartnerCallback);
            }
        });

        window.scrollTo(0, 0);
    }

    const checkSystemAssets = async (id) => {
        await setWaitDelete(true);
        setLoading(true);
        await AssetService.get(id).then(response => {
            if (response.status === 200 && response.data !== undefined && response.data !== null && response.data.length > 0) {
                setuserassethierarchy(response.data);
                setIsDeleted(false);
                setIsActiveBillingPlan(false);
            } else {
                setMessage("Failed to delete the System! Reason: " + response.data);
                setIsDeleted(false);
                setIsActiveBillingPlan(false);
            }
            setTimeout(() => {
                setLoading(false)
                setWaitDelete(false);
            }, 3000);

        })
        .catch(e => {
            console.log(e);
        });
    }

    const deleteSystem = async (id) => {
        setLoading(true);
        await setWaitDelete(true);
        await AccountService.deletesystem(id)
            .then(response => {
                if (response.status === 200 && response.data !== undefined && response.data !== null && response.data === "Success") {
                    fetchDeletedSystems();
                    setIsDeleted(true);
                    console.log(isDeleted);
                    setMessage("The System was deleted successfully!");
                    setIsActiveBillingPlan(false);
                    Event("Customer Delete", "User deleted customer System", "Successful Customer System Deletion");
                } else if (response.status === 200 && response.data !== undefined && response.data !== null && response.data === "Active") {
                    setMessage("Need to cancel the subscription first before delete the System!");
                    setIsDeleted(false);
                    setIsActiveBillingPlan(false);
                    setLoading(false);
                } else {
                    setMessage("Failed to delete the System! Reason: " + response.data + ". " + actionMessage);
                    setIsDeleted(false);
                    setIsActiveBillingPlan(false);
                    setLoading(false);
                }
                setTimeout(() => {
                    setWaitDelete(false);
                    setLoading(false);
                }, 3000);
            })
            .catch(e => {
                console.log(e);
                setLoading(false);
            });
    }

    const restoreSystem = async () => {
        setLoading(true);
        await setWaitDelete(true);
        await AccountService.recoverSystem(currentSystemId, selectedHome.houseAssetId, currentCustomerOrg)
            .then(response => {
                if (response.status === 200 && response.data !== undefined && response.data !== null && response.data === "Success") {
                    setShowRecoverConfirmation(!showRecoverConfirmation);
                    fetchDeletedSystems();
                    setMessage("The System was recovered successfully!");
                    setIsDeleted(true);
                    setIsActiveBillingPlan(false);
                    Event("Customer Recover", "User recovered customer System", "Successful Customer System Recovery");
                } else {
                    setMessage("Failed to recover the System! Reason: " + response.data + ". " + actionMessage);
                    setIsDeleted(false);
                    setIsActiveBillingPlan(false);
                    setLoading(false);
                }
                setTimeout(() => {
                    setWaitDelete(false);
                    setLoading(false);
                }, 3000);
            })
            .catch(e => {
                console.log(e);
                setLoading(false);
            });
    }

    // END DELETE FLOW

    const onColumnClick = (selectedCol) => {
        const colSort = columnSort;
        let newSort = colSort;
        const tableList = deletedSystems;
        let metrics = tableList;
        let colAssets = [];
        let colAltered = false;

        if (colSort.enabled) {
            if (colSort.type == selectedCol) {
                if (colSort.reverse) {
                    newSort = {
                        enabled: false,
                        type: null,
                        reverse: false
                    };
                    colAssets = sortColumn(metrics, "ID", false); /* default sort by is DEVICEID */
                    colAltered = false;
                }
                else {
                    newSort = {
                        enabled: true,
                        type: selectedCol,
                        reverse: true
                    };
                    colAssets = sortColumn(metrics, selectedCol, true);
                    colAltered = true;
                }
            }
            else {
                newSort = {
                    enabled: true,
                    type: selectedCol,
                    reverse: false
                };
                colAssets = sortColumn(metrics, selectedCol, false);
                colAltered = true;
            }
        }
        else {
            newSort = {
                enabled: true,
                type: selectedCol,
                reverse: false
            };
            colAssets = sortColumn(metrics, selectedCol, false);
            colAltered = true;
        }

        setColumnSort(newSort, () => {
            setColumnList(colAssets, () => {
                setColumnAltered(colAltered);
            });
        });
    }

    function sortColumn(dataList, col, isReverse) {
        let colData = [];
        let data = dataList;


        if (col === "ID") {
            colData = data.sort((a, b) => {
                const numA = a.organizationId;
                const numB = b.organizationId;

                return numB - numA;
            });
        }
        else if (col === "NAME") {
            colData = data.sort((a, b) => {
                const stringA = a.name;
                const stringB = b.name;

                return stringA.localeCompare(stringB);
            });
        }
        else if (col === "DESCRIPTION") {
            colData = data.sort((a, b) => {
                const stringA = a.description;
                const stringB = b.description;

                return stringA.localeCompare(stringB);
            });
        }
        else {
            colData = data;
        }

        if (isReverse) {
            colData = colData.reverse();
        }

        return colData;
    }

    let arrowIcon = columnSort.enabled ?
        (columnSort.reverse ?
            <LongArrowDownIcon style={{ height: '16px', width: '15px', paddingBottom: '2px' }} />
            : <LongArrowUpIcon style={{ height: '16px', width: '15px', paddingBottom: '2px' }} />)
        : null;

    let idIcon = columnSort.enabled && columnSort.type === "ID" ?
        <span style={{ height: '15px', paddingLeft: '3px' }}>
            {arrowIcon}
        </span>
        : null;

    let nameIcon = columnSort.enabled && columnSort.type === "NAME" ?
        <span style={{ height: '15px', paddingLeft: '3px' }}>
            {arrowIcon}
        </span>
        : null;

    let descriptionIcon = columnSort.enabled && columnSort.type === "DESCRIPTION" ?
        <span style={{ height: '15px', paddingLeft: '3px' }}>
            {arrowIcon}
        </span>
        : null;

    let contents = loading
        ? <Loader />
        :
        <div className="table-container">
            <table className="table-striped table-bordered table-sm table-hover" style={{ width: '100%' }}>
                <thead>
                    <tr className="alerts-table-row">
                        <th style={{ paddingLeft: '30px' }}>
                            <div onClick={() => onColumnClick("ID")}>
                                <span style={{ cursor: 'pointer' }}>ASSET ID</span>
                                {idIcon}
                            </div>
                        </th>
                        <th>
                            <div onClick={() => onColumnClick("NAME")}>
                                <span style={{ cursor: 'pointer' }}>NAME</span>
                                {nameIcon}
                            </div>
                        </th>
                        <th>
                            <div onClick={() => onColumnClick("DESCRIPTION")}>
                                <span style={{ cursor: 'pointer' }}>CUSTOMER</span>
                                {descriptionIcon}
                            </div>
                        </th>
                        <th>
                            <div>
                                <span style={{ cursor: 'pointer' }}>ACTIONS</span>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {deletedSystems.map(system => (
                        <tr className="alerts-table-row" key={system.assetId}>
                            <td style={{ borderLeft: '1px solid #ebeef0', paddingLeft: '30px' }}>{system.assetId}</td>
                            <td>{system.name}</td>
                            <td>{system.orgName}</td>
                            {/* this stays the same below */}
                            <td style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <button className="primary-btn btn-small" style={{ borderRadius: '0.75rem', width: '25%' }}
                                    title="Restore system"
                                    onClick={() => showRecoverConfirmationModal(system)}
                                >
                                    <RestoreFromTrashIcon color="white" fontSize="small"/>
                                </button>
                                <button className="red-btn btn-small" style={{ borderRadius: '0.75rem', width: '25%' }}
                                    title="Permanently Delete system"
                                    onClick={() => showConfirmationModal(system)}
                                >
                                    <DeleteForeverIcon color="white" fontSize="small"/>
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <ConfirmPasswordModal
                show={showPasswordConfirmation}
                onCancelClick={showPasswordConfirmationModal}
                onYes={showDeleteSystemModal}
                onLogOff={null} />


            {/* <ConfirmDeleteSystemModal
                onClick={showRecoverConfirmationModal}
                show={showRecoverConfirmation}
                onConfirm={restoreSystem}
                systemId={currentSystemId}
                messageBody={message}
                loading={waitDelete}
                isDeleted={isDeleted}
                isActiveBillingPlan={isActiveBillingPlan}
                onCancelClick={cancelSystemDelete}
            /> */}

            <ConfirmationModal
                show={showConfirmation}
                onYes={showPasswordConfirmationModal}
                onClick={cancelSystemDelete}
                message={<UserDeviceList data={userassethierarchy} />}
                title={modalTitle} />

            <ConfirmDeleteSystemModal
                onClick={showDeleteSystemModal}
                show={showDeleteSystem}
                onConfirm={() => deleteSystem(currentSystemId)}
                systemId={currentSystemId}
                messageBody={message}
                loading={waitDelete}
                isDeleted={isDeleted}
                isActiveBillingPlan={isActiveBillingPlan}
                onCancelClick={cancelSystemDelete}
            />

            <SelectHomeDataModal 
                onClick={showRecoverConfirmationModal} 
                show={showRecoverConfirmation} 
                onHomeSelect={onHomeChange} 
                onRestore={restoreSystem} 
                homes={currentHomeList} 
                mySelectedHome={currentHomeList.indexOf(selectedHome)}
            />

        </div>;

    return (
        <div sx={(theme)=>({
            [theme.breakpoints.down('md')]: {
                marginLeft: '-225px !important',
            },
        })}>
            <div className='infoPage'>
                <h3 className="pageTitle" id="tabelLabel" >Deleted Systems</h3>
                {contents}
            </div>
        </div>
    );
};

export default DeleteSystems;